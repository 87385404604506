* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
  background: none;
  border: none;
}

body {
  color: #ecf0f1;
  background-color: #1c1c1c;
  font-family: Afacad Flux, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.hidden-player {
  display: none;
}

header {
  color: #ecf0f1;
  text-align: center;
  padding: 30px;
  box-shadow: 0 1px 30px #0000004d;
}

.record-player {
  width: 100%;
  max-width: 1200px;
  margin: 50px auto 0;
  padding: 20px;
}

.base {
  background: #2a2a2a;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  display: flex;
  box-shadow: 0 10px 30px #000000e6;
}

footer {
  text-align: center;
  color: #ecf0f1;
  background-color: #333;
  margin-top: 50px;
  padding: 60px;
  box-shadow: 0 -1px 30px #0000004d;
}

footer a {
  color: #c0392b;
}

.speed-control {
  flex: 1;
  position: relative;
}

#speed-button {
  color: #ecf0f1;
  cursor: pointer;
  background-color: #444;
  border-radius: 8px;
  align-items: center;
  gap: 5px;
  padding: 15px;
  transition: background-color .3s;
  display: flex;
}

#speed-button:hover {
  background-color: #555;
}

#speed-value {
  font-size: 14px;
  font-weight: 500;
}

#speed-button .bi-chevron-down {
  margin-left: auto;
  transition: transform .3s;
}

.speed-dropdown {
  z-index: 1002;
  background-color: #2a2a2a;
  border: 1px solid #555;
  border-radius: 8px;
  display: none;
  position: absolute;
  top: -110%;
  left: 0;
  box-shadow: 0 4px 8px #00000080;
}

.speed-dropdown.active {
  display: flex;
}

.speed-option {
  color: #ecf0f1;
  text-align: left;
  cursor: pointer;
  background: none;
  padding: 10px 15px;
  font-size: 14px;
  transition: background-color .3s;
}

.speed-option:hover {
  background-color: #444;
}

#speed-button[aria-expanded="true"] .bi-chevron-up {
  transform: rotate(180deg);
}

.media-center {
  color: #fff;
  opacity: 1;
  visibility: visible;
  z-index: 999;
  background-color: #222;
  border-radius: 10px;
  width: 90%;
  max-height: 80vh;
  transition: transform .3s, opacity .3s, visibility .3s;
  position: fixed;
  bottom: 0;
  right: 5%;
  overflow: visible;
  transform: translateY(160px);
  box-shadow: 0 -4px 8px #0000004d;
}

.media-center.active {
  transform: translateY(-20px);
}

.media-center-wrapper {
  padding: 20px;
  position: relative;
}

.media-center-toggle {
  color: #fff;
  cursor: pointer;
  z-index: 1000;
  background-color: #333;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  transition: background-color .3s;
  display: flex;
  position: absolute;
  top: -220px;
  box-shadow: 0 4px 6px #0000004d;
}

.media-center-toggle:hover {
  background-color: #555;
}

.media-center-toggle-wrapper {
  justify-content: center;
  width: 100%;
  display: flex;
  position: relative;
}

.media-center.active .media-center-toggle i {
  transform: rotate(180deg);
}

.turntable-container {
  width: 250px;
  height: 250px;
  position: relative;
}

.turntable {
  background: radial-gradient(circle, #444, #222);
  border: 10px solid #555;
  width: 100%;
  height: 100%;
  transition: transform .5s ease-in-out;
  position: relative;
  overflow: visible;
  box-shadow: inset 0 0 10px #000c;
}

.platter {
  background: #333;
  border: 10px solid #555;
  border-radius: 50%;
  width: 80%;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.record {
  z-index: 1;
  background: radial-gradient(circle, #0004 60%, #555);
  border-radius: 50%;
  width: 70%;
  height: 70%;
  transition: animation-duration .5s;
  animation: 5s linear infinite spin;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 15px #00000080;
}

.grooves {
  opacity: .4;
  pointer-events: none;
  background: repeating-conic-gradient(#0000 0deg 4deg, #0002 160deg 4deg);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: 5s linear infinite spinGrooves;
  position: absolute;
  top: 0;
  left: 0;
}

.record .label {
  background: radial-gradient(circle, #ff5733 40%, #c0392b);
  border-radius: 50%;
  width: 30%;
  height: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: inset 0 0 5px #0000004d;
}

.grooves:before {
  content: "";
  opacity: .1;
  background: repeating-linear-gradient(0deg, #0000, #0000 9px, #555 10px);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.tonearm {
  transform-origin: 0%;
  z-index: 2;
  background: #777;
  border-radius: 8px;
  width: 150px;
  height: 6px;
  transition: transform 1.5s;
  position: absolute;
  top: 10%;
  left: 5%;
  transform: rotate(90deg);
}

.needle {
  background: #bdc3c7;
  border-radius: 8px;
  width: 25px;
  height: 4px;
  position: absolute;
  top: -3px;
  right: -10px;
  box-shadow: 0 0 5px #0000004d;
}

.visuals {
  flex-wrap: wrap;
  flex: .5;
  justify-content: center;
  gap: 20px;
  display: flex;
}

#visualizer {
  background-color: #333;
  border: 1px solid #555;
  border-radius: 8px;
  width: 100%;
  height: 100px;
  box-shadow: inset 0 0 10px #00000080;
}

.content-viewer {
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  display: flex;
}

.content-viewer .control-group {
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  display: flex;
}

.control-group {
  align-items: center;
  gap: 15px;
  width: 100%;
  display: flex;
}

.control-group.volume-control {
  position: relative;
}

.volume-control .volume-slider-container {
  z-index: 1001;
  background: #2a2a2af2;
  border: 1px solid #555;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 100px;
  padding: 8px;
  transition: opacity .3s, visibility .3s;
  display: none;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 2px 10px #00000080;
}

.volume-control.active .volume-slider-container {
  display: flex;
}

.volume-control .volume-slider-container input[type="range"] {
  appearance: none;
  transform-origin: center;
  cursor: pointer;
  background: none;
  border-radius: 3px;
  outline: none;
  width: 100px;
  height: 6px;
  transition: background .3s;
  transform: rotate(-90deg);
}

.volume-control .volume-slider-container input[type="range"]::-webkit-slider-thumb {
  cursor: pointer;
  background: #e74c3c;
  border: none;
  border-radius: 8px;
  width: 8px;
  height: 32px;
  transition: background .3s, transform .2s;
}

.volume-control .volume-slider-container input[type="range"]::-moz-range-thumb {
  cursor: pointer;
  background: #e74c3c;
  border: none;
  border-radius: 8px;
  width: 8px;
  height: 32px;
  transition: background .3s, transform .2s;
}

.volume-control .volume-slider-container input[type="range"]::-webkit-slider-thumb:hover {
  background: #c0392b;
  transform: scale(1.2);
}

.volume-control .volume-slider-container input[type="range"]::-moz-range-thumb:hover {
  background: #c0392b;
  transform: scale(1.2);
}

.volume-control #volume-button {
  color: #ecf0f1;
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-size: 1.5rem;
  transition: color .3s;
  display: flex;
}

.volume-control #volume-button:hover {
  color: #e74c3c;
}

.media-center-wrapper {
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}

.menu-button {
  color: #ecf0f1;
  cursor: pointer;
  background-color: #444;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 1.5rem;
  transition: background-color .3s;
  display: flex;
}

.menu-button:hover {
  background-color: #555;
}

.toggled-controls {
  background-color: #2a2a2a;
  border-radius: 8px;
  gap: 10px;
  width: 100%;
  padding: 10px;
  transition: max-height .3s;
  display: none;
}

.toggled-controls.active {
  display: flex;
}

.playback-controls {
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.playback-controls .control-wrapper {
  gap: 15px;
}

.playback-controls .control-group.now-playing {
  text-align: center;
  color: #ecf0f1;
  font-weight: 700;
}

.now-playing {
  text-align: center;
  color: #ecf0f1;
  font-size: 16px;
  font-weight: 700;
}

#track-name {
  color: #ecf0f1;
  text-align: center;
  word-wrap: break-word;
  font-size: 18px;
}

button, select {
  cursor: pointer;
  appearance: none;
  border: none;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  transition: background-color .3s, transform .2s, box-shadow .3s;
  display: flex;
}

select {
  width: unset;
}

button {
  color: #fff;
  background-color: #e74c3c;
}

button:hover {
  background-color: #c0392b;
  transform: scale(1.05);
  box-shadow: 0 4px 15px #e74c3c66;
}

select {
  color: #ecf0f1;
  background-color: #333;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
}

#add-youtube-url {
  color: #fff;
  background-color: #e74c3c;
}

#add-youtube-url:hover {
  background-color: #c0392b;
}

#skip, #go-back {
  color: #fff;
  background-color: #444;
  font-size: 1rem;
}

#skip:hover, #go-back:hover {
  background-color: #555;
}

.volume-control .volume-button i {
  transition: color .3s;
}

.progress-container {
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
}

.time {
  color: #ecf0f1;
  text-align: center;
  min-width: 40px;
  font-size: 14px;
}

.progress-bar {
  cursor: pointer;
  background-color: #555;
  border-radius: 8px;
  width: 100%;
  height: 8px;
  transition: background-color .3s;
  position: relative;
  overflow: hidden;
}

.progress-bar:hover {
  background-color: #666;
}

.progress {
  background-color: #e74c3c;
  width: 0%;
  height: 100%;
  transition: width .1s linear;
}

.queue-dropzone {
  color: #ecf0f1;
  text-align: center;
  cursor: pointer;
  background-color: #e74c3c1a;
  border: 2px dashed #e74c3c;
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  transition: background-color .3s, border-color .3s;
}

.queue-dropzone.dragover, #turntable-dropzone.dragover {
  background-color: #e74c3c33;
  border-color: #c0392b;
}

.queue-list {
  width: 100%;
  max-height: 100px;
  padding: 0;
  list-style: none;
  overflow-y: auto;
}

.queue-list li {
  cursor: move;
  background-color: #333;
  border-bottom: 1px solid #444;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  transition: background-color .3s, opacity .3s, border .3s;
  display: flex;
}

.queue-list li:hover {
  background-color: #555;
}

.queue-list li.dragging {
  opacity: .5;
  border: 2px dashed #e74c3c;
}

.queue-list li p {
  text-overflow: ellipsis;
  flex: 10;
  margin: 0;
  overflow: hidden;
}

.remove-btn {
  color: #fff;
  cursor: pointer;
  background-color: #e74c3c;
  border-radius: 8px;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  transition: background-color .3s;
  display: flex;
}

.remove-btn:hover {
  background-color: #c0392b;
}

.youtube-input {
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
}

#youtube-url-input {
  border: 1px solid #ccc;
  border-radius: 8px;
  flex: 1;
  padding: 8px;
}

.loading {
  z-index: 1000;
  color: #333;
  background: #fffc;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  display: flex;
  position: fixed;
  inset: 0;
}

.hidden {
  display: none;
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%)rotate(0);
  }

  to {
    transform: translate(-50%, -50%)rotate(360deg);
  }
}

@keyframes spinGrooves {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.paused {
  animation-play-state: paused;
}

.playing .tonearm {
  transform: rotate(60deg);
}

.paused .tonearm {
  transform: rotate(90deg);
}

@media (width >= 800px) {
  .turntable-container {
    width: 300px;
    height: 300px;
  }

  .tonearm {
    width: 200px;
  }

  #visualizer {
    height: 150px;
  }

  .queue-list {
    max-height: 150px;
  }

  .media-center {
    padding: 20px 40px;
  }

  .media-center .control-group {
    gap: 20px;
  }

  .menu-button {
    display: none;
  }

  .toggled-controls {
    display: flex !important;
  }

  .media-center {
    transform: translateY(240px);
  }

  .media-center-toggle {
    top: -270px;
  }
}

@media (width >= 1100px) {
  .base {
    flex-direction: row;
    justify-content: space-between;
    padding: 40px;
  }

  .turntable-container {
    width: 400px;
    height: 400px;
  }

  .tonearm {
    width: 250px;
  }

  .media-center {
    width: 70%;
    padding: 25px 60px;
    right: 15%;
  }

  .media-center .control-group {
    gap: 25px;
  }
}
/*# sourceMappingURL=index.adf37ed9.css.map */
